footer.site-footer {
  background: url("../img/bottom-background.png") center no-repeat;
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;

  h2 {
    @include philosopher-font;

    color: #fff;
    font-size: 25px;
    margin: 0;
  }

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer-bottom {
    background: $dark-violet;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer-left {
    p {
      font-size: 19px;
      color: #fff;
      margin: 0;
    }

    form {
      display: flex;
      flex-direction: column;
      max-width: 590px;

      .action-button {
        background-color: $dark-violet;
        flex: 1;

        &:hover {
          background: $violet;
        }
      }
    }
  }

  .social {
    color: $dark-violet;
    display: flex;
    align-items: center;

    a {
      color: $dark-violet;

      &:hover {
        color: $violet;
      }
    }
  }

  .submit-button-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .action-button {
    max-width: 240px;
  }

  #newsletter-form-submit-successful {
    display: none;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    color: #fff;

    svg {
      width: 130px;
      color: $dark-violet;
      flex-shrink: 0;
    }

    span {
      display: block;
      font-size: 26px;
      font-weight: 600;
      padding-left: 40px;
      color: #fff;
      line-height: 40px;
    }
  }

  #newsletter-form-submit-error {
    display: none;
  }

  input {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    h2 {
      font-size: 36px;
      margin-bottom: 14px;
    }

    &>.container-small {
      display: flex;
      padding-top: 120px;
      min-height: 550px;
    }

    .footer-left {
      flex-grow: 1;

      p {
        margin-bottom: 25px;
      }
    }

    .footer-right {
      width: 360px;
      text-align: right;
    }

    .social {
      justify-content: flex-end;

      .facebook {
        height: 33px;
        margin-right: 16px;
      }

      .instagram {
        height: 31px;
        margin-right: 16px;
      }

      .youtube {
        height: 24px;
      }
    }
  }

  .error-message {
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(md) {
    &>.container-small {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .social {
      justify-content: center;
      align-items: stretch;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          display: block;
        }

        &:nth-child(2) {
          margin: 0 10px;
        }
      }
    }

    .footer-right {
      h2 {
        margin-bottom: 8px;
      }

      text-align: center;
      margin-bottom: 30px;

      svg {
        width: 100%;
        max-width: 25px;
        max-height: 25px;
      }
    }

    .footer-left {
      text-align: center;

      h2 {
        margin-bottom: 4px;
      }

      h2+p {
        font-size: 14px;
        margin-bottom: 18px;
      }

      .submit-button-row {
        justify-content: center;
      }
    }

    #newsletter-form-submit-successful {
      flex-direction: column;
      text-align: center;

      svg {
        margin-bottom: 10px;
        width: 110px;
      }

      span {
        padding: 0;
        font-size: 22px;
      }
    }
  }
}