@use "sass:color";
@import "variables.scss";
@import "home.scss";
@import "footer.scss";
@import "components/site_bar.scss";
@import "listings.scss";
@import "content_page.scss";
@import "activities.scss";
@import "articles.scss";
@import "contact.scss";
@import "forms.scss";

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  padding-top: 98px;
  transition: padding-top 0.2s ease;
  background: #fff;

  @include mullish-font;

  @include media-breakpoint-down(lg) {
    padding-top: 78px;
  }

  &.page-background {
    background: #fff url("../img/top-background.png") center top no-repeat;
    background-size: 100% 300px;



    @include media-breakpoint-between(sm, lg) {
      background-size: 100% 290px;
    }

    @include media-breakpoint-down(sm) {
      background-size: 100% 254px;
    }

    .page {
      &-title {
        @include square-peg-font;
        font-size: 81px;
        font-weight: normal;
        color: #fff;
        text-align: center;
        margin: 40px 0 136px;

        // @include media-breakpoint-down(xl) {
        //   margin: 0 0 18%;
        // }

        @include media-breakpoint-down(md) {
          font-size: 60px;
          margin: 70px 0 110px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 60px;
          margin: 46px 0 90px;
        }
      }

      &-subtitle {
        display: block;
        text-align: center;
      }
    }
  }
}

a {
  color: #000;
  text-decoration: none;
}

.container-large {
  max-width: 1600px;

  @include media-breakpoint-up(xl) {
    width: calc(100% - 220px);
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% - 80px);
  }

  @include media-breakpoint-between(md, lg) {
    width: calc(100% - 50px);
  }

  @include media-breakpoint-down(md) {
    width: calc(100% - 40px);
  }
}

.container-wrapper {
  display: flex;
  justify-content: center;
}

.orange-background {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -18px;
    top: -18px;
    background: $orange;
  }
}

.action-button-container {
  flex-shrink: 0;
}

.action-button {
  @include mullish-font;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
  background: $violet;
  color: #fff;
  line-height: 46px;
  padding: 0 78px;
  text-decoration: none;
  transition: background-color 0.08s linear;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(#949494, 60%);

  @include media-breakpoint-down(lg) {
    padding: 0 36px;
    font-size: 15px;
  }

  &:hover {
    background: color.adjust($violet, $lightness: -8%);
  }

  &.green {
    background: $vibrant-green;
    color: #000;
    font-weight: 600;

    &:hover {
      background: color.adjust($vibrant-green, $lightness: -10%);
    }
  }

  &.orange {
    background: $light-orange;
    color: #000;

    &:hover {
      background: color.adjust($light-orange, $lightness: -10%);
    }
  }

  &.loading {
    background: #ccc !important;

    .loader {
      display: block;
    }

    .text {
      display: none;
    }
  }

  .loader {
    margin: 8px 0;
    width: 30px;
    height: 30px;
    border-width: 3px;
    display: none;
  }

  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 38px;
  }
}

.large-quote {
  position: relative;
  z-index: 1;

  &.violet {
    i:last-of-type {
      &::after {
        background: $violet;
      }
    }

    svg {
      color: $violet;
    }
  }

  &.gray {
    i:last-of-type {
      &::after {
        background: $highlight-grey;
      }
    }

    svg {
      color: $highlight-grey;
    }
  }

  &.orange {
    i:last-of-type {
      &::after {
        background: $orange;
      }
    }

    svg {
      color: $orange;
    }
  }

  &.quote-top {
    svg {
      top: -21px;
      bottom: auto;
      transform: rotate(180deg);
    }
  }

  i:last-of-type {
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      display: block;
      width: calc(100% + 37px);
      height: 16px;
      background: $violet;
      position: absolute;
      left: -4px;
      bottom: -4px;
      z-index: -1;
    }
  }

  svg {
    width: 48px;
    position: absolute;
    left: 50%;
    margin-left: -24px;
    bottom: -30px;

    @include media-breakpoint-down(md) {
      bottom: unset;
      top: -12px;
      width: 30px;
    }
  }

  &,
  & p {
    font-size: 30px !important;
    text-align: center;
    line-height: 40px !important;

    i {
      @include square-peg-font;

      font-size: 50px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @include philosopher-font;

  .container-small {
    padding: 120px 0px;

  }

  p {
    margin: 0;
  }

  &.background-violet {
    background: $background-violet;
  }

  &.background-grey {
    background: $background-violet;
  }

  @include media-breakpoint-between(md, lg) {

    &,
    & p {
      font-size: 24px !important;
      text-align: center;
      line-height: 40px !important;

      i {
        font-size: 46px;
      }
    }

    .container-small {
      padding: 96px 0 145px;
    }
  }

  @include media-breakpoint-between(sm, md) {

    &,
    & p {
      font-size: 22px !important;
      text-align: center;
      line-height: 33px !important;

      i {
        font-size: 38px;
      }
    }

    .container-small {
      padding: 73px 0 122px;
    }
  }

  @include media-breakpoint-down(sm) {

    &,
    & p {
      font-size: 17px !important;
      text-align: center;
      line-height: 30px !important;

      i {
        font-size: 30px;
      }
    }

    .container-small {
      padding: 58px 0 106px;
    }
  }

  @media (max-width: 440px) {

    &,
    & p {
      font-size: 19px !important;
      text-align: center;
      line-height: 31px !important;
      padding: 0px 36px;

      i {
        font-size: 31px;
      }
    }

    .container-small {
      padding: 40px 0;
    }
  }

  @media (max-width: 399px) {

    &,
    & p {
      font-size: 14px !important;
      text-align: center;
      line-height: 22px !important;

      i {
        font-size: 24px;
      }
    }

    .container-small {
      padding: 50px 0 87px;
    }
  }

  @media (max-width: 360px) {

    &,
    & p {
      font-size: 13px !important;
      text-align: center;
      line-height: 22px !important;

      i {
        font-size: 20px;
      }
    }

    .container-small {
      padding: 44px 0 80px;
    }
  }
}

.centered-container {
  display: flex;
  justify-content: center;
}

.highlighted-title {
  @include philosopher-font;

  font-size: 43px;
  margin: 0 0 40px;
  display: inline-block;
  position: relative;
  font-weight: bold;

  &>span {
    box-shadow: inset 0 -19px $vibrant-green;
    padding: 0 11px 3px;
    box-decoration-break: clone;
  }

  &.offset-left>span {
    padding: 0 11px 3px 20px;
    position: relative;
    left: -20px;
  }

  &.background-violet>span {
    box-shadow: inset 0 -19px $background-violet;
  }

  &.background-orange>span {
    box-shadow: inset 0 -19px $orange;
  }

  &.small-underline {
    &>span {
      box-shadow: inset 0 -16px $vibrant-green;
    }

    &.background-violet>span {
      box-shadow: inset 0 -16px $violet;
    }

    &.background-orange>span {
      box-shadow: inset 0 -16px $orange;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 36px;
    margin-bottom: 32px;

    &>span {
      box-shadow: inset 0 -19px $vibrant-green;
      padding: 0 11px;
      box-decoration-break: clone;
    }

    &.offset-left>span {
      padding: 0 11px;
      position: relative;
      left: -22px;
    }

    &.background-violet>span {
      box-shadow: inset 0 -19px $background-violet;
    }

    &.background-orange>span {
      box-shadow: inset 0 -19px $orange;
    }

    &.small-underline {
      &>span {
        box-shadow: inset 0 -12px $vibrant-green;
      }

      &.background-violet>span {
        box-shadow: inset 0 -12px $background-violet;
      }

      &.background-orange>span {
        box-shadow: inset 0 -12px $orange;
      }
    }
  }
}

.embed-container {
  padding-bottom: 56.24%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.error-message {
  background: #fef2f2;
  border: 1px solid color.adjust(#dc2626, $lightness: 40%);
  border-radius: 6px;
  color: #b91c1c;
  padding: 20px;
  margin-bottom: 40px;
}

.horizontal-cta {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;

  @include media-breakpoint-up(sm) {
    &::before {
      content: "";
      display: block;
      width: calc(50vw - 200px);
      height: 1px;
      position: absolute;
      top: 50%;
      background: #9e9e9e;
    }

    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: calc(50% - 6px);
      border-radius: 6px;
      background: #9e9e9e;
    }

    &.left {
      &::before {
        left: calc((100vw - 100%) / -2);
      }

      &::after {
        left: calc(50% - 200px);
      }
    }

    &.right {
      &::before {
        right: calc((100vw - 100%) / -2);
      }

      &::after {
        right: calc(50% - 200px);
      }
    }
  }

  .action-button {
    flex-grow: 0;
  }
}

.loader {
  border: 5px solid rgb(0 0 0 / 10%);
  border-left-color: transparent;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: loader-animation 1s linear infinite;
}

.slim-container {
  max-width: 600px;
  width: 100%;
}

.share-page {
  display: flex;

  svg {
    width: 100%;
    height: 100%;
    max-width: 28px;
    max-height: 28px;
    color: $orange;
    transition: color 0.1s linear;
  }

  a {
    display: block;

    &:hover svg {
      color: color.adjust($orange, $lightness: -10%);
    }

    &:not(:last-child) {
      margin-right: 25px;
    }
  }
}

.signup-button {
  margin-top: 50px;

  @include media-breakpoint-up(sm) {
    .share-page {
      position: absolute;
      left: 20px;
      top: 10px;
    }
  }

  @include media-breakpoint-up(md) {
    .share-page {
      left: 50px;
    }
  }

  @include media-breakpoint-down(sm) {
    .share-page {
      display: none;
    }
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@include media-breakpoint-up(xl) {
  .container-small {
    width: 1150px;
  }
}

@media (min-width: 1090px) and (max-width: map-get($breakpoints, xl)) {
  .container-small {
    width: 1030px;
    padding: 0 30px;
  }
}

@media (max-width: 1090px) {
  .container-small {
    width: 100%;
    padding: 0 30px;
  }
}

@include media-breakpoint-down(md) {
  .container-small {
    padding: 0 50px;
  }
}

@include media-breakpoint-down(sm) {
  .container-small {
    padding: 0 20px;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    width: 1230px;
    padding: 0 30px;
  }
}

@include media-breakpoint-between(lg, xl) {
  .container {
    width: 100%;
    padding: 0 30px;
  }
}

@include media-breakpoint-between(md, lg) {
  .container {
    padding: 0 30px;
  }
}

@include media-breakpoint-down(md) {
  .container {
    padding: 0 20px;
  }
}
