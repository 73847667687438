body {
  background: red;
  width: 100vw;
  overflow-x: hidden;

  &.template-homepage {
    @include media-breakpoint-between(lg, xl) {
      .container-small {
        max-width: 900px;
      }
    }
  }
}

h2.title {
  @include philosopher-font;

  font-size: 36px;
  margin: 0 0 42px;

}

.home-intro-container {
  display: flex;
  flex-direction: column;
  position: relative;
}


.home-intro {
  width: 100%;
  flex-direction: row;
  padding: 140px 275px 128px;
  align-items: center;

  @include media-breakpoint-down(xxl) {
    padding: 70px 70px 128px !important;
  }

  @include media-breakpoint-down(md) {
    padding: 60px 0 !important;
    text-align: center;
  }



  &-section-1-container {
    background: url(../img/FL-home-1.png) 85% 100% no-repeat, radial-gradient(circle at 65%, #C3C3EA, #A19ECE 38%) no-repeat;
    background-size: 550px, 100%;
    height: 600px;

    @include media-breakpoint-between(xl, xxl) {
      background: url(../img/FL-home-1.png) 110% 100% no-repeat, radial-gradient(circle at 86%, #C3C3EA, #A19ECE 38%) no-repeat;
      background-size: 453px, 100%;
      height: 480px;
    }

    @include media-breakpoint-between(md, xl) {
      background: url(../img/FL-home-1.png) 110% 100% no-repeat, radial-gradient(circle at 80%, #C3C3EA, #A19ECE 38%) no-repeat;
      background-size: 450px, 100%;
      height: 480px;
    }

    // @include media-breakpoint-between(md, lg) {
    //   background: url(../img/FL-home-1.png) 110% 100% no-repeat, radial-gradient(circle at 80%, #C3C3EA, #A19ECE 38%) no-repeat;
    //   background-size: 390px, 100%;
    //   height: 480px;
    // }

    @include media-breakpoint-down(md) {
      background: url(../img/FL-home-1.png) center 100% no-repeat, radial-gradient(circle at center 60%, #C3C3EA, #A19ECE 60%) center no-repeat;
      background-size: 337px, 100% !important;
      height: 580px;
      background-origin: content-box, padding-box;
      padding-left: 110px !important;
   }
  }

  &-section-2 {
    padding: 110px 70px !important;
    width: 100%;
    display: flex;
    position: relative;

    @include media-breakpoint-down(lg) {
      padding: 60px 40px !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 70px 40px !important;
    }


    &-container {
      background: url(./../img/home-intro-background-1.png) center no-repeat;
      margin-top: -98px;

      @include media-breakpoint-down(xl) {
        margin-top: -53px;
      }

      @include media-breakpoint-down(lg) {
        background: url(./../img/home-intro-background-1-tablet.png) no-repeat;
        margin-top: -60px;
      }

      @include media-breakpoint-down(md) {
        margin-top: -30px;
      }

      @include media-breakpoint-down(sm -170px) {
        background: url(./../img/home-intro-background-1-mobile.png) center no-repeat;
        margin-top: -20px;
        background-size: 100%
      }
    }
  }


  .intro-text {
    @include philosopher-font;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 280px;
    width: 100%;

    @include media-breakpoint-down(md) {
      align-items: center;
    }


    &__section {
      &-1 {
        max-width: 660px;
        color: #fff;
        font-size: 50px;

        p {
          margin: 0 !important;

          b {
            box-shadow: inset 0 -19px $background-violet;
            padding: 0 10px 0 19px;
            margin-left: -19px;
            font-weight: 100;
          }

          i {
            @include media-breakpoint-down(md) {
              box-shadow: none !important;
            }
          }

        }

        &__top-title>p>br {
          content: "";
          margin: -27px;
          display: block;

          @include media-breakpoint-down(sm) {
            margin: -15px !important;
          }
        }

        @-moz-document url-prefix() {
          &__top-title>p>br {
            content: "";
            margin: -12px;
            display: block;

          }
        }

        @include media-breakpoint-down(xl) {
          font-size: 40px;
        }

        @include media-breakpoint-down(md) {
          font-size: 31px;
          margin-left: -110px !important;

        }

        i {
          font-size: 96px !important;

          @include media-breakpoint-down(xl) {
            font-size: 76px !important;
          }

          @include media-breakpoint-down(md) {
            font-size: 59px !important;
          }
        }

        .bottom-negative-spacing {
          margin-bottom: -25px;
        }

        .text-max-width {
          max-width: 440px;
        }

        .top-spacing {
          margin-top: 50px;

          @include media-breakpoint-down(xl) {
            margin-top: 60px;
          }

          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }
        }
      }

      &-2 {
        display: flex;
        flex-direction: column;
        gap: 60px;

        .intro-text__section-2__link {
          position: relative;

          @include media-breakpoint-up(xl) {
            top: -48px;
          }

          @include media-breakpoint-between(lg, xl) {
            top: -66px;
          }

          .highlighted-title {
            margin-bottom: 0 !important;
          }
        }

        @include media-breakpoint-down(md) {
          gap: 10px;
        }

        .padding-start {
          padding-left: 20px;
        }

        &__text {
          display: flex;
          flex-direction: column;
          gap: 19px;

          @include media-breakpoint-down(md) {

            gap: 4px;



            span {
              font-size: 19px !important;
              @include mullish-font;
              box-shadow: none !important;
            }

            .padding-start {
              padding-left: 0;
            }
          }

          &-1 {
            max-width: 580px;

            @include media-breakpoint-down(md) {
              font-weight: 100;
              display: inline;

              section {
                display: inline;
              }
            }

            span {
              font-size: 36px;

              @include media-breakpoint-down(lg) {
                font-size: 28px;
              }

              @include media-breakpoint-down(md) {
                font-size: 19px;
                font-weight: 100;
                padding: 0;
              }
            }

            .mulish-text {
              font-size: 24px;

              @include media-breakpoint-down(lg) {
                font-size: 19px !important;
                font-weight: 100;
                max-width: 460px;
              }

              @include media-breakpoint-down(md) {
                font-size: 13px;
              }
            }

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &-2 {
            max-width: 830px;


            @include media-breakpoint-down(md) {
              font-weight: 100;
              display: inline;

              section {
                display: inline;
              }
            }

            span {
              font-size: 36px;

              @include media-breakpoint-down(lg) {
                font-size: 28px;
              }

              @include media-breakpoint-down(md) {
                font-size: 19px;
                font-weight: 100;
                padding: 0;
              }
            }

            .mulish-text {
              font-size: 24px;

              @include media-breakpoint-down(lg) {
                font-size: 19px !important;
                max-width: 646px;

              }
            }

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }
        }



        &__link {
          i {
            font-size: 60px;
            font-weight: normal;
            -webkit-box-shadow: inset 0 -19px $light-violet;
            box-shadow: inset 0 -19px $light-violet;
            color: #000 !important;

            @include media-breakpoint-down(lg) {
              font-size: 48px !important;
            }

            @include media-breakpoint-down(sm) {
              font-size: 45px !important;
            }

            p {
              margin: 0 !important;
              position: absolute;
              top: 8px;
              right: -17px;
            }

            span {
              box-shadow: none !important;
              position: relative;
              top: 11px;

              @include media-breakpoint-down(sm) {
                top: 5px;
              }
            }
          }
        }
      }
    }

    .white-text {
      color: #FFF
    }

    .regular {
      margin: 0;
      font-size: 36px;
    }

    .illustration {
      position: relative;
      top: -202px;
      left: 113px;
      background: url("../img/home-intro-vertical-shape.svg") no-repeat;
      background-size: auto 100%;
      background-position: left top;
      width: calc(50vw - -11px);
      height: 1542px;
    }



    &__section-1 {
      &__title {
        font-size: 50px !important;
        font-weight: normal !important;

        @include media-breakpoint-between(md, xxl) {
          font-size: 40px !important;
        }

        @include media-breakpoint-down(md) {
          font-size: 31px !important;
          margin: 0;
        }
      }

      &__subtitle {
        font-size: 96px !important;

        @include media-breakpoint-between(md, xxl) {
          font-size: 76px !important;
        }

        @include media-breakpoint-down(md) {
          font-size: 59px !important;
        }
      }

      &__bottom-title {
        font-size: 24px !important;

        @include media-breakpoint-between(md, xxl) {
          font-size: 19px !important;
        }

        @include media-breakpoint-down(md) {
          font-size: 15px !important;
        }
      }
    }


    .white-text {
      color: #FFF
    }

    .mulish-text {
      @include mullish-font;
      font-size: 24px;
      font-weight: 300;
    }

    p {
      margin: 24px 0;
      display: inline;
    }

    p:last-of-type {
      margin-top: -15px;
    }

    i {
      font-style: normal;
      font-size: 60px;
      color: #4D4D4D;

      @include square-peg-font;

      @include media-breakpoint-down(md) {
        margin: 35px 0 0;
      }

      @include media-breakpoint-down(sm) {
        -webkit-box-shadow: inset 0 -12px #a19ece;
        box-shadow: inset 0 -12px #a19ece;
        margin: 0;
      }
    }
  }

  .intro-image {
    position: relative;
    overflow: visible;

    img {
      display: block;
      position: relative;
      z-index: 2;
      height: auto;
    }

    .image-background {
      display: block;
      background: $vibrant-green;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 16px;
      top: 16px;
      z-index: 1;
    }
  }
}





@include media-breakpoint-up(lg) {
  .home-intro {
    width: 100%;

    .intro-text {
      min-height: 507px;

      &__section {
        &-2 {
          display: flex;
          align-items: center;

          &__text-1 {
            margin-left: -35%;
          }
        }
      }
    }
  }
}

@include media-breakpoint-between(xl, xxl) {
  .home-intro {
    width: 100%;
    padding-top: 140px;

    .intro-text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 280px;

      &__section {
        &-1 {
          max-width: 660px;
        }

        &-2 {

          &__text-1 {
            max-width: 580px;

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__text-2 {
            max-width: 830px;

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__link {
            i {
              font-weight: normal;
              -webkit-box-shadow: inset 0 -19px $light-violet;
              box-shadow: inset 0 -19px $light-violet;
              color: #000;
            }
          }
        }
      }

      .white-text {
        color: #FFF
      }

      .regular {
        margin: 0;
      }

      .illustration {
        position: relative;
        top: -202px;
        left: 113px;
        background: url("../img/home-intro-vertical-shape.svg") no-repeat;
        background-size: auto 100%;
        background-position: left top;
        width: calc(50vw - -11px);
        height: 1542px;
      }
    }

    .intro-image img {
      width: 380px;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .home-intro {
    width: 100%;
    padding-top: 130px;

    .intro-text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 280px;
      padding-top: 36px;

      &__section {
        &-1 {
          max-width: 660px;
        }

        &-2 {
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__text-1 {
            max-width: 580px;

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__text-2 {
            max-width: 830px;

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__link {
            i {
              font-weight: normal;
              -webkit-box-shadow: inset 0 -19px $light-violet;
              box-shadow: inset 0 -19px $light-violet;
              color: #000;
            }

          }
        }
      }

      .white-text {
        color: #FFF
      }

      .regular {
        margin: 0;
      }

      .illustration {
        position: relative;
        top: -202px;
        left: 113px;
        background: url("../img/home-intro-vertical-shape.svg") no-repeat;
        background-size: auto 100%;
        background-position: left top;
        width: calc(50vw - -11px);
        height: 1542px;
      }
    }

    .intro-image img {
      width: 340px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .home-intro {
    .intro-text__section-2__text {
      margin-top: 26px;
    }

    .intro-text__section-2 {
      position: relative;
      gap: 64px;
    }

    .intro-text__section-2__text-2 {
      position: relative;
      left: 38px;
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .home-intro {
    .intro-text__section-2__text {
      margin-top: 16px;
    }

    .intro-text__section-2 {
      position: relative;
      gap: 64px;
    }

    .intro-text__section-2__text-2 {
      position: relative;
      left: 68px;
    }
  }
}

@include media-breakpoint-down(md) {
  .intro-text__section-2 {
    .mulish-text {
      font-size: 13px !important;
    }

    b {
      font-weight: bold !important;
    }

    &__link {
      i {
        font-size: 30px !important;
        font-weight: normal;
        -webkit-box-shadow: inset 0 -12px $light-violet;
        box-shadow: inset 0 -12px $light-violet;
        color: #000;

        p {
          margin: 0 !important;
          position: absolute;
          top: 8px;
          right: -17px;
        }
      }
    }
  }

}

@include media-breakpoint-between(md, lg) {
  .home-intro {
    .intro-text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 280px;
      padding-top: 36px;

      &__section {
        &-1 {
          max-width: 660px;
        }

        &-2 {
          display: flex;
          flex-direction: column;
          gap: 12px;


          &__text-1 {
            max-width: 580px;
            padding-left: 60px;


            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__text-2 {
            margin-left: 145px;
            max-width: 1100px;

            .gray {
              -webkit-box-shadow: inset 0 -19px $highlight-grey;
              box-shadow: inset 0 -19px $highlight-grey;
            }
          }

          &__link {
            align-self: center;

            i {
              font-weight: normal;
              -webkit-box-shadow: inset 0 -19px $light-violet;
              box-shadow: inset 0 -19px $light-violet;
              color: #000;
            }

          }
        }
      }

      .white-text {
        color: #FFF
      }

      .regular {
        margin: 0;
      }

      .illustration {
        position: relative;
        top: -202px;
        left: 113px;
        background: url("../img/home-intro-vertical-shape.svg") no-repeat;
        background-size: auto 100%;
        background-position: left top;
        width: calc(50vw - -11px);
        height: 1542px;
      }
    }

    .intro-image img {
      width: 280px;
    }
  }
}

// Section 2
.home-section-2 {
  .section-2-text {
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
  }

  .section-2-button {
    display: flex;
    justify-content: flex-start;

    .background-light-orange {
      background: $light-orange;
      color: #000;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-top: 40px;
    }
  }

  .container-small {
    display: flex;
    gap: 120px;
    padding-bottom: 140px;

    @include media-breakpoint-down(md) {
      padding-bottom: 0 !important;
      gap: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 50px;
    position: relative;

    .section-2-text {
      width: 500px;
      margin-bottom: 50px;

      .background-light-orange {
        background: $light-orange;
        color: #000;
      }
    }

    .left {
      padding-top: 42px;

      .image {
        background: url("../img/home-section-2-about-us.png")center -20px no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        z-index: 10;
        width: 425px;
        height: 480px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
    }

    .section-2-text>br {
      content: "";
      margin: 45px;
      display: block;

    }
  }

  @include media-breakpoint-between(xl, xxl) {
    .container-small {
      width: 954px;
    }

    padding-top: 46px;

    .section-2-text {
      max-width: 490px;
      margin-bottom: 40px;
      padding-right: 20px;
    }

    .right {
      .image {
        width: 381px;
        height: 420px;
      }
    }
  }

  @include media-breakpoint-between(lg, xl) {
    .container-small {
      width: 950px;
      padding-left: 0;
    }

    padding-top: 70px;

    .section-2-text {
      max-width: 490px;
      margin-bottom: 40px;
      padding-right: 40px;
      font-size: 18px;
      line-height: 28px;
    }

    .right {
      padding-right: 20px;
    }

  }

  @include media-breakpoint-between(md, xl) {
    .left {
      .image {
        width: 340px !important;
        height: 380px !important;
      }
    }

    .right {
      h2 {
        font-size: 29px;
      }
    }

    .section-2-text>br {
      content: "";
      margin: 27px;
      display: block;

    }
  }

  @include media-breakpoint-between(md, lg) {
    .container-small {
      width: 800px;
      padding-bottom: 100px;
      gap: 50px;
    }

    padding-top: 120px;

    .section-2-text {
      max-width: 433px;
      margin-bottom: 40px;
      font-size: 15px;
      line-height: 19px;
      padding-right: 40px;
    }
  }

  @include media-breakpoint-up(xl) {
    .container-small {
      width: 960px !important;
    }
  }


  @include media-breakpoint-down(md) {
    .container-small {
      flex-direction: column-reverse;
      align-items: center;
      padding-bottom: 60px;
    }

    .title {
      text-align: center;
      position: relative;
      left: 11px;
      font-size: 22px;
      margin-bottom: 36px;
    }

    .left {
      padding-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-wrapper {
        width: 100%;
        padding-bottom: 70px;

        .orange-background {
          width: 100%;
          height: 100%;

          &::before {
            left: -14px;
            top: -14px;
          }

          .image {
            width: 300px;
            height: 331px;
          }
        }
      }

      .image {
        width: 300px;
        height: 331px;
      }
    }

    .section-2-text {
      max-width: 400px;
      font-size: 12px;
      line-height: 13px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 40px;
      max-width: 386px;
      width: calc(100% - 14px);


    }

    .section-2-text {
      max-width: 520px;
      margin-bottom: 50px;

      .background-light-orange {
        background: $light-orange;
        color: #000;
      }
    }

    .left {
      .image {
        background: url("../img/home-section-2-about-us.png")center -20px no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        z-index: 10;
        width: 260px;
        height: 300px;
      }
    }
  }
}

.home-section-3 {
  background: $light-orange;
  color: #000000;
  height: 415px;

  @include media-breakpoint-down(lg) {
    height: 345px;
  }

  @include media-breakpoint-down(md) {
    height: 260px;
  }


  .container-small {
    position: relative;

    p>br {
      content: "";
      margin: 10px;
      display: block;
    }
  }

  @include media-breakpoint-up(xxl) {
    .container-small::after {
      right: -56px;
    }
  }

  @include media-breakpoint-between(xl, xxl) {
    .container-small::after {
      right: 59px;
    }
  }

  @media (min-width: 1090px) and (max-width: map-get($breakpoints, xl)) {
    .container-small::after {
      right: 49px;
    }
  }

  @media (min-width: map-get($breakpoints, lg)) and (max-width: 1090px) {
    .container-small::after {
      right: calc(50vw - 466px);
    }
  }

  @include media-breakpoint-between(md, lg) {
    i {
      &::after {
        height: 14px;
        bottom: -39px;
        width: calc(100% + 38px);
      }
    }

    svg {
      width: 42px;
      margin-left: -21px;
      bottom: -18px;
    }
  }

  @include media-breakpoint-between(sm, md) {
    i {
      &::after {
        height: 11px;
        bottom: -32px;
        width: calc(100% + 33px);
        left: -5px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    i {
      &::after {
        height: 8px;
        bottom: -28px;
        width: calc(100% + 30px);
        left: -5px;
      }
    }

    svg {
      width: 34px;
      margin-left: -17px;
      bottom: -15px;
    }
  }

  @media (max-width: 440px) {
    i {
      &::after {
        height: 7px;
        bottom: -21px;
        width: calc(100% + 25px);
        left: -5px;
      }
    }
  }

  @media (max-width: 399px) {
    i {
      &::after {
        height: 6px;
        bottom: -18px;
        width: calc(100% + 22px);
        left: -4px;
      }
    }
  }

  @media (max-width: 360px) {
    i {
      &::after {
        height: 5px;
        bottom: -14px;
        width: calc(100% + 19px);
        left: -3px;
      }
    }
  }


  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

.home-activities {
  padding: 140px 0 150px;

  @include media-breakpoint-down(md) {
    padding: 60px 0;

  }

  h2.title {
    margin-bottom: 74px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }

    @include media-breakpoint-down(md) {
      font-size: 22px;

    }
  }

  .activities-container {
    .activity {
      color: #000;

      img {
        width: 100%;
        height: auto;
      }
    }

    h3 {
      @include media-breakpoint-down(md) {
        font-size: 18px;
        max-width: 150px;
      }

    }

    p {
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .activities-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;

      .activity {
        max-width: 520px;

        h3 {
          font-size: 30px;
          font-weight: 500;
          margin: 24px 0 10px;
          max-width: 265px;
          padding-left: 16px;

        }

        span.date {
          display: block;
          font-weight: 400;
          color: #808080;
          font-size: 19px;
          line-height: 23px;
          margin-bottom: 4%;
          padding-left: 16px;

        }

        p {
          font-weight: 100;
          font-size: 19px;
          line-height: 24px;
          padding-left: 16px;
          max-width: 430px;

        }
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    padding: 110px 40px;

    .activities-container {
      display: flex;
      justify-content: space-between;

      .activity {
        width: calc((100% - 50px) / 2);
      }

    }

    .centered-container {
      margin-top: 75px;

      .action-button {
        padding: 0 75px;
      }
    }
  }

  @include media-breakpoint-between(sm, md) {
    padding-top: 100px;

    .activities-container {
      .activity {
        width: calc((100% - 30px) / 2);
      }
    }
  }

  @include media-breakpoint-down(sm) {

    h2.title {
      text-align: center;
      margin-bottom: 40px;
    }

    .activities-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .activity {
        display: block;
        max-width: 380px;
        margin-bottom: 40px;
      }
    }
  }
}

.home-videos {
  background: $light-green;
  padding: 130px 0 110px;

  @include media-breakpoint-down(md) {
    padding: 50px 60px;
  }

  .action-button {
    padding: 0 75px;

    @include media-breakpoint-down(md) {
      padding: 0 36px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 140px;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      font-size: 22px;
      margin-bottom: 35px;
    }
  }

  img {
    width: 100%;
    display: block;
    border-radius: 15px;
    margin-bottom: 22px;
  }

  .video {
    h3 {
      color: #1d1d1b;
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;

      @include media-breakpoint-down(md) {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }


  .videos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    padding: 0 70px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .video {
      width: 30%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

}

.home-articles {
  padding: 60px 0 240px;

  @include media-breakpoint-down(lg) {
    padding: 160px 55px 200px;
  }

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  img {
    max-width: 100%;
    display: block;
  }

  h2 {
    margin-bottom: 80px;
    font-size: 30px;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }

  .articles-container {
    display: flex;
    flex-direction: column;
    gap: 130px;

    @include media-breakpoint-down(md) {
      gap: 40px;
    }
  }

  .article {
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 115px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 40px;
      align-items: center;
    }

  }

  .article-content {
    @include media-breakpoint-down(lg) {
      max-width: 295px;
    }

    h3 {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      margin: 0 0 45px;
      max-width: 500px;

      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 35px;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;

      }
    }

    .article-date {
      font-weight: 500;
      text-transform: uppercase;
      color: #808080;
      font-size: 14px;
      margin-bottom: 16px;

      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
    }

    p {
      font-size: 19px;
      line-height: 24px;
      color: #1d1d1b;
      max-width: 500px;
      margin: 19px 0 50px;

      @include media-breakpoint-down(lg) {
        font-size: 15px;
        line-height: 19px;
        margin: 19px 0 30px;
      }

      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 15px;
        margin: 19px 0 30px;
      }
    }

    a.read-more {
      font-style: italic;
      font-size: 16px;
      font-weight: 500;
      color: $violet;
      display: inline-block;
      position: relative;
      padding-bottom: 6px;

      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: $violet;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &:hover::after {
        display: none;
      }
    }


  }

  .article-image {
    @include media-breakpoint-up(lg) {
      margin-top: 6px;
    }

    img {
      display: block;
      width: 496px;
      height: 380px;

      @include media-breakpoint-down(lg) {
        width: 395px;
        height: 245px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .article {
      display: flex;
      justify-content: space-between;

      .article-content {
        max-width: 500px;
      }

    }
  }

  .centered-container {
    margin-top: 100px;

    .action-button {
      padding: 0 75px;

      @include media-breakpoint-down(md) {
        padding: 0 36px;
      }
    }
  }
}

.home-section-7 {
  background: $grey-background;
  margin-bottom: -45px;
  z-index: -1 !important;

  @include media-breakpoint-down(md) {
    padding: 0 20px !important;
    height: 280px;

  }

  .container-small {
    p {
      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }

    }
  }

  p {
    b {
      i {
        color: #666666;
      }
    }
  }

  p>br {
    display: none;
  }

  @include media-breakpoint-up(md) {
    p>br {
      content: "";
      margin: 10px;
      display: block;
    }
  }

}
