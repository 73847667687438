.listing {
  display: flex;
  justify-content: center;
  padding-bottom: 140px;

  .listing-item {
    display: block;
    margin-bottom: 80px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    h3 {
      font-size: 26px;
      font-weight: 500;
      color: #1d1d1b;
    }

    p {
      font-weight: 300;
      font-size: 18px;
    }

    .listing-item-date {
      font-weight: 500;
      text-transform: uppercase;
      color: #808080;
    }
  }

  @include media-breakpoint-up(xl) {
    .listing-container {
      & > *:nth-child(3n + 1),
      & > *:nth-child(3n + 2) {
        margin-right: 45px;
      }
    }

    .listing-item {
      width: calc((100% - 90px) / 3);
    }
  }

  @include media-breakpoint-between(md, xl) {
    .listing-container {
      & > *:nth-child(2n + 1) {
        margin-right: 45px;
      }
    }

    .listing-item {
      width: calc((100% - 45px) / 2);
    }
  }

  @include media-breakpoint-up(md) {
    .listing-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}
