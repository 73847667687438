body.page-background.contact-page {
  /* background-image: url("../img/top-background-green.png"); */
  background-image: none;
  background-color: #daefda;

  h1 {
    color: #000;
  }
}

.contact {
  .contact-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 30px;

    a {
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 20px;
      font-weight: 300;
      font-size: 21px;
      line-height: 30px;

      &.email,
      &.whatsapp {
        svg {
          margin-right: 7px;
        }
      }
    }

    svg {
      max-width: 30px;
      max-height: 30px;
      width: 100%;
      color: $vibrant-green;
      vertical-align: middle;
      display: inline-block;
    }

    .social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 120px;
    }
  }
}
