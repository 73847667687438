.content-page {
  .content-page-heading {
    text-align: center;

    h1 {
      @include philosopher-font;

      font-size: 43px;
      margin: 0 0 40px;
      display: inline-block;

      span {
        position: relative;
      }
    }

    @include media-breakpoint-down(md) {
      h1 {
        font-size: 35px;
      }
    }
  }

  img {
    width: auto;
    max-height: 600px;
  }

  .content-page-content {
    padding-bottom: 80px;

    p,
    ul,
    ol {
      font-size: 21px;
      font-weight: 300;
      line-height: 38px;
      color: #1d1d1b;
    }

    .block-image {
      display: flex;
      justify-content: center;
      margin: 60px 0;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }

      @include media-breakpoint-down(md) {
        margin: 30px 0;
      }
    }

    .block-embed {
      margin: 60px 0;

      div {
        width: 100%;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .block-text {
      ul {
        padding: 17px;
        list-style-type: "-  ";
        margin-bottom: 0;

        li:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .block-text {
        padding: 0 50px;
      }

      p {
        &:not(:last-child) {
          margin: 0 0 40px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .block-text {
        padding: 0 20px;
      }

      p,
      ul,
      ol {
        font-size: 17px;
        line-height: 26px;
      }

      p {
        &:not(:last-child) {
          margin: 0 0 20px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .block-text {
        padding: 0;
      }
    }
  }
}

.block-color-background-text {
  position: relative;
  overflow-y: visible;
  margin-bottom: 40px;

  .inner {
    position: relative;
    z-index: 1;
    padding: 20px 0;
  }

  &::before {
    content: "";
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: calc((100vw - 100%) / -2);
    top: 0;
    z-index: 0;
  }

  &.color-orange::before {
    background: $light-orange;
  }
}

.block-quote {
  margin-bottom: 56px;

  .container-wrapper {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: calc((100vw - 100%) / -2);
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: 0;
    }

    .container-small {
      position: relative;
      z-index: 1;
      padding: 90px 0;
    }

    &.background-violet::before {
      background: $background-violet;
    }
  }

  .author {
    @include mullish-font;

    color: #666;
    font-style: italic;
    font-size: 20px;
  }
}

.block-two-columns {
  p:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
    padding: 0 50px;
    display: flex;

    .block-two-columns-image {
      position: relative;
      margin-right: 40px;
      width: calc(50% - 40px);
      flex-shrink: 0;
      padding-bottom: calc(42% - 40px);

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    &.image_left {
      flex-direction: row-reverse;
    }

    &.image_right {
      .block-two-columns-image {
        margin-left: 40px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;

    p:last-child {
      margin-bottom: 25px;
    }

    .block-two-columns-image {
      position: relative;
      width: 100%;
      padding-bottom: 40%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}
