#activiy-signup-form {
  padding-top: 8px;
}

#activity-form-submitting {
  .loader {
    border-color: $dark-violet;
    border-left-color: transparent;
  }
}

#activity-form-submit-successful {
  svg {
    color: $dark-violet;
  }
}

.activity.listing-item {
  .activity-image {
    position: relative;
  }

  .activity-date {
    color: #808080;
    font-weight: 600;
  }
}

.activity-detail {
  .subtitle {
    @include philosopher-font;

    font-style: italic;
    font-size: 30px;
    margin-bottom: 30px;
  }

  .subtitle-secondary {
    font-size: 24px;
    color: #808080;
    margin-bottom: 30px;
  }

  @include media-breakpoint-between(sm, md) {
    .subtitle {
      font-size: 24px;
      margin-bottom: 24px;
    }

    .subtitle-secondary {
      font-size: 20px;
      margin-bottom: 24px;
    }
  }

  @include media-breakpoint-down(sm) {
    .subtitle {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .subtitle-secondary {
      font-size: 17px;
      margin-bottom: 18px;
    }
  }

  .main-image {
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;

    img {
      height: auto;
      width: auto;
      max-width: 100%;
    }

    .activity-banner {
      font-size: 26px;
      line-height: 42px;
      right: 0;
      top: 30px;
      min-width: 200px;
      text-align: left;
    }
  }

  .activity-dates {
    @include philosopher-font;

    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }
}

.activity-banner {
  @include philosopher-font;

  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-size: 17px;
  white-space: nowrap;
  padding: 0 30px 0 25px;
  line-height: 36px;
  position: absolute;
  right: 0;
  top: 14px;
  text-align: left;
  min-width: 140px;

  &.color-green {
    background: $vibrant-green;
  }

  &.color-orange {
    background: $orange;
  }

  &.color-purple {
    background: $violet;
  }
}

.final-share {
  padding-left: 50px;
  margin: 80px 0;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}

body.activity-signup-page.page-background {
  background: $background-violet url("../img/page-background-white.png") center -20px no-repeat;
  background-size: 1295px auto;

  .page-title {
    color: $dark-violet;
  }
}

.activity-signup {
  display: block;
}
