$light-green: #daefda;
$vibrant-green: #9c9;
$orange: #f2b06f;
$light-orange: #f9de96;
$background-violet: #c3c3ea;
$light-violet: #a19ece;
$violet: #817ab7;
$dark-violet: #664f97;
$grey-background: #e1e1e1;
$highlight-grey: #c7c7c7;

@mixin mullish-font {
  font-family: Mulish, sans-serif;
}

@mixin philosopher-font {
  font-family: Philosopher, sans-serif;
}

@mixin square-peg-font {
  font-family: "Square Peg", cursive;
}

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
$transition-time: 0.5s;
$content-margin: 16px;

@function breakpoint-min($name) {
  @return map-get($breakpoints, $name);
}

@function breakpoint-max($name) {
  @return map-get($breakpoints, $name) - 0.02;
}

@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @media (min-width: $min) {
    @content;
  }
}

@mixin media-breakpoint-down($name) {
  $max: breakpoint-max($name);

  @media (max-width: $max) {
    @content;
  }
}

@mixin media-breakpoint-between($min-name, $max-name) {
  $min: breakpoint-min($min-name);
  $max: breakpoint-max($max-name);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}
