.article-listing {
  .article-content {
    padding: 0 12px;
  }
}

.article-detail {
  .article-date {
    font-size: 19px;
    text-transform: uppercase;
    color: #808080;
    font-weight: 600;
    margin-bottom: 36px;
  }
}
