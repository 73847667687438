#site-bar {
  width: 100vw;
  background: white;
  box-shadow: 0 0 12px rgba(0 0 0 / 30%);
  height: 98px;
  position: fixed;
  left: 0;
  top: 0;
  transition: top 0.3s ease, opacity 0.3s ease;
  z-index: 500;
  display: flex;
  justify-content: center;

  .container-large {
    display: flex;
    justify-content: space-between;
  }

  .bar-start {
    flex-grow: 1;
  }

  @include media-breakpoint-down(lg) {
    height: 78px;
  }

  #logo {
    display: block;
    margin: 0;
    position: relative;
    top: 24px;
    height: 50px;
    background: url("../img/logo-fanny-libertun.svg") no-repeat;
    background-size: contain;
    width: 306px;

    span {
      visibility: hidden;
    }

    @include media-breakpoint-between(lg, xl) {
      top: 25px;
      width: 285px;
    }

    @media (max-width: 1040px) {
      top: 19px;
      height: 42px;
    }

    @media (max-width: 1040px) and (min-width: map-get($breakpoints, "lg")) {
      top: 26px;
      width: 274px;
    }

    @include media-breakpoint-down(sm) {
      top: 19px;
      width: 240px;
    }
  }

  #nav-toggle {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 24px;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
    }
  }

  #main-nav {
    margin-top: 10px;
  }

  .menu-right {
    position: relative;
    right: -2px;
  }

  ul.nav {
    display: flex;
    margin: 0;
    padding-left: 0;

    & > li {
      padding-bottom: 26px;
    }

    li {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 16px;
      transition: padding-top 0.2s ease;
      position: relative;

      &.dropdown {
        & > a {
          &::after {
            content: "";
            display: inline-block;
            background: url("../img/caret-down.svg") center no-repeat;
            width: 7px;
            height: 6px;
            background-size: contain;
            vertical-align: middle;
            margin-left: 5px;
          }
        }
      }

      a {
        display: block;
        padding: 15px;
        background: #fff;
        color: #292929;

        &:hover {
          color: $dark-violet;
          text-decoration: underline;
        }
      }

      &:hover ul {
        display: block;
      }

      ul {
        position: absolute;
        left: 0;
        top: 88px;
        padding: 0;
        box-shadow: 0 0 12px rgba(0 0 0 / 30%);
        background: $light-violet;
        display: none;

        li {
          padding: 0;
          margin: 0;
        }

        &::before {
          content: "";
          display: block;
          width: calc(100% + 20px);
          height: 14px;
          background: #fff;
          position: absolute;
          left: -10px;
          top: -14px;
        }

        a {
          display: block;
          width: 200px;

          &:hover {
            background: $light-violet;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }

    @include media-breakpoint-between(lg, xl) {
      ul {
        li {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    transition: padding-top 0.2s ease;

    img {
      width: 192px;
      display: block;
    }
  }
}

.menu-icon {
  position: relative;
  transform: scale(var(--ggs, 1));
}

.menu-icon,
.menu-icon::after,
.menu-icon::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 23px;
  height: 2px;
  border-radius: 2px;
  background: #9e9e9e;
}

.menu-icon::after,
.menu-icon::before {
  content: "";
  position: absolute;
  top: -7px;
}

.menu-icon::after {
  top: 7px;
}

body.nav-hidden {
  #site-bar {
    top: -98px;
  }
}

.mm-ocd {
  .caret {
    display: none;
  }

  .mm-spn {
    background-color: #fff !important;
  }
}

body.mm-ocd-opened {
  #site-bar {
    opacity: 0;
  }
}
