form {
  .input-wrapper {
    input.just-validate-error-field,
    textarea.just-validate-error-field,
    select.just-validate-error-field {
      border-color: color.adjust(#dc2626, $lightness: 20%);
    }
  }
}

input[type="text"],
input[type="email"] {
  line-height: 42px;

  @include media-breakpoint-down(md) {
    line-height: 36px;
  }
}

select {
  height: 46px;
}

textarea {
  line-height: 32px;

  @include media-breakpoint-down(md) {
    line-height: 26px;
  }
}

input[type="text"],
input[type="email"],
textarea,
select {
  @include mullish-font;

  padding: 0 16px;
  font-size: 20px;
  font-weight: 300;
  border: 2px solid #fff;
  margin-bottom: 10px;
  outline: none;
  background: #fff;

  &:disabled {
    border-color: $grey-background;
  }

  @include media-breakpoint-down(md) {
    font-size: 17px;
  }
}

.form-wrapper {
  & > .container-small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-subtitle {
    text-align: left;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;

    .input-wrapper {
      width: 100%;
      margin-bottom: 26px;
    }

    label {
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 9px;
    }

    input,
    textarea,
    select {
      width: 100%;
    }

    input[type="submit"] {
      width: auto;
    }

    textarea {
      resize: vertical;
      min-height: 200px;
      padding: 16px;
    }
  }

  .form-submit-error {
    max-width: 600px;
    display: none;
  }

  .form-submitting,
  .form-submit-successful {
    padding: 70px 0;
    text-align: center;
    display: none;

    svg {
      max-width: 160px;
      display: block;
      margin: 0 auto 30px;
      color: $vibrant-green;
    }

    span {
      @include philosopher-font;

      font-size: 30px;
      display: block;
      max-width: 600px;
      margin: 0 auto 30px;
    }

    .loader {
      margin: 0 auto 40px;
      border-color: $vibrant-green;
      border-left-color: transparent;
    }
  }
}
